import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TranslationTable from "./Table/Table";
import { AppContext } from "../../context/AppContext";
import TabsManager from "./Tabs/Tabs";
import CreateLanguage from "./create /CreateLanguage";

const Translation = () => {
  const { user } = useContext(AppContext);
const [showLanguageModal, setShowLanguageModal] = useState(false);
const [modalData, setModalData] = useState(false);

  const onCreateTranslation = () => {
    setModalData(false);
    setShowLanguageModal(true)
  };
  return (
    <>
      <div className="admin-page-title">
        <h2>Translation</h2>
        <Button
          variant="success"
          className="space-right btn-success"
          onClick={() => onCreateTranslation()}
          disabled={!user.state.admin && !user.state.permissions.user.roles}
        >
          <FontAwesomeIcon icon={faPlus} /> Create Language Translation
        </Button>
      </div>
      <TabsManager />
      <CreateLanguage
      show={showLanguageModal}
      onHide={() => setShowLanguageModal(false)}
      />
    </>
    
  );
};


export default Translation;
