import React, { useState, useEffect, useContext } from "react";
import Table from "react-bootstrap/Table";
import Axios from "axios";
import ReactPaginate from "react-paginate";
import { Form, Button, Row, Col } from "react-bootstrap";
import FilterTranslation from "../include/FilterTranslation";
import TranslationDropdown from "../include/DropdownButton";
import UpdateTranslationApi from "../utils/UpdateTranslationApi";
import UpdateEngliasTranslationApi from "../utils/UpdateEnglishTranslation";
import { toast } from "react-toastify";
const TranslationTable = ({ Apidata ,Fetchtabledata}) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [items, setItems] = useState();
  const [pages, setPages] = useState();
  const [UpdatedValue, setUpdatedValue] = useState({});
  const [updatevaluecall, setUpdatedvaluecall] = useState({ Translation: [] });
  const [submitting, setSubmitting] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [filterdArr, setFilteredArr] = useState([]);
  useEffect(() => {
    setItems(filterdArr?.slice(page * perPage, (page + 1) * perPage));
    setPages(Math.floor(filterdArr?.length / perPage) + 1);
  }, [filterdArr]);
  useEffect(() => {
    setItems(filterdArr?.slice(page * perPage, (page + 1) * perPage));
  }, [page]);
  useEffect(()=>{
      setPage(0)
  },[filterdArr])
  useEffect(() => {
    let obj = filterObj;
    let _translation = Apidata?.translation;
    if (Object.keys(obj).length > 0 && obj.query) {
      if (!obj.selection) {
        _translation = Apidata?.translation.filter((el) => {
          if (
            el.id_local.toLowerCase().includes(obj.query) ||
            el.source.toLowerCase().includes(obj.query) ||
            el.translated.toLowerCase().includes(obj.query) ||
            el.category.toLowerCase().includes(obj.query)
          ) {
            return el;
          }
        });
      } else if (obj.selection === "ID") {
        _translation = Apidata?.translation.filter((el) => {
          if (el.id_local.toLowerCase().includes(obj.query)) {
            return el;
          }
        });
      } else if (obj.selection === "Translation") {
        _translation = Apidata?.translation.filter((el) => {
          if (el.translated.toLowerCase().includes(obj.query)) {
            return el;
          }
        });
      } else if (obj.selection === "Source") {
        _translation = Apidata?.translation.filter((el) => {
          if (el.source.toLowerCase().includes(obj.query)) {
            return el;
          }
        });
      } else if (obj.selection === "Category") {
        _translation = Apidata?.translation.filter((el) => {
          if (el.category.toLowerCase().includes(obj.query)) {
            return el;
          }
        });
      }
    }
    setFilteredArr(_translation);
  }, [filterObj]);
  const handlePageClick = (event) => {
    let page = event.selected;
    setPage(page);
  };
  const ChsngeTranslation = async () => {
    setSubmitting(true);
    let response =[]
if(Apidata?.language==='English'){

  response = await UpdateEngliasTranslationApi({ updatevaluecall });
}
else{
   response = await UpdateTranslationApi({ updatevaluecall });

}
    setSubmitting(false);
    if (!response.error) {
      toast.success("Translation Updated");
      if(Apidata?.language==='English'){
      Fetchtabledata()
      }
    } else {
      toast.error("Try again! Translation is not Updated");
    }
  };
  useEffect(() => {
    if (Object.keys(UpdatedValue).length > 0) {
      console.log(UpdatedValue)
      let previousVal = updatevaluecall.Translation;
      var foundIndex = updatevaluecall.Translation.findIndex(x => x._id === UpdatedValue._id);
      if (foundIndex> -1){
        previousVal[foundIndex] = UpdatedValue;
        setUpdatedvaluecall({ Translation: [...previousVal] })
      }else{
      setUpdatedvaluecall({ Translation: [...previousVal, UpdatedValue] });
      }
    }
  }, [UpdatedValue]);
  return (
    <>
      <>
        <Row style={{ marginTop: "20px" }}>
          <FilterTranslation
            data={Apidata}
            setFilter={setFilterObj}
            // setFilteredMatches={searchFilteredMatches}
            // stageIndex={stageIndex}
            // groupIndex={groupIndex}
          />
          {/* <Col sm={3}>
            <TranslationDropdown apidata={Apidata}/>
          </Col> */}
          <Col sm={1}>
            <Button style={{ width: "inherit" }} onClick={ChsngeTranslation}>
              {submitting ? "Saving..." : "Save"}
            </Button>
          </Col>
        </Row>
        <Row className="table-group-row">
          <Col style={{ display: "flex", flexDirection: "column" }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Id</th>
                  {Apidata?.isDefault === true ? null : <th>Source</th>}
                  <th>Translation</th>
                  <th>Category</th>
                </tr>
              </thead>
              <tbody>
                {items?.map((word, Index) => (
                  <tr key={word?._id}>
                    <td style={{ width: "5%", height: "auto" }}>{word.id_local}</td>
                    {Apidata?.isDefault === true ? null : (
                      <td style={{ width: "35%" }}>
                        <Form.Group className="mb-0 group-sizes">
                          <Form.Control required type="text" name="groups" value={word.source} disabled />
                        </Form.Group>
                      </td>
                    )}
                    <td style={{ width: "35%" }}>
                      <Form.Group className="mb-0 group-sizes">
                        <Form.Control
                          required
                          type="text"
                          name="groups"
                          defaultValue={word.translated}
                          readOnly={false}
                          onChange={(e) => {
                            setUpdatedValue({
                              "_id": word._id,
                              "translation": e.target.value,
                              "id_local":word.id_local,
                              "source": e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </td>
                    <td style={{ width: "20%" }}>
                      <Form.Group className="mb-0 group-sizes">{word.category}</Form.Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ alignSelf: "center" }}>
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                pageCount={pages}
                forcePage={page}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
              />
            </div>
          </Col>
        </Row>
      </>

      <div></div>
    </>
  );
};
export default TranslationTable;
