import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import ColorPicker from "../../../../common/ColorPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import EditImageModal from "../../../../_modals/EditImageModal/EditImageModal";
import EditVideoModal from "../../../../_modals/EditVideoModal/EditVideoModal";

const General = ({ game }) => {
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const whiteIcon = watch("whiteIcon", game.whiteIcon);
  const blackIcon = watch("blackIcon", game.blackIcon);
  const horizontalDisplayImage = watch("horizontalDisplayImage", game.horizontalDisplayImage);
  const verticalDisplayImage = watch("verticalDisplayImage", game.verticalDisplayImage);
  const frontPageImage = watch("frontPageImage", game.frontPageImage);
  const frontPageVideo = watch("frontPageVideo", game.frontPageVideo);
  const [show, setShow] = useState(false);
  const scrimFinderBanner = watch("scrimFinderBanner", game.scrimFinderBanner);
  const defaultTeamLogo = watch("defaultTeamLogo", game.defaultTeamLogo);
  const defaultTeamBanner = watch("defaultTeamBanner", game.defaultTeamBanner);
  const overViewPageBanner = watch("overViewPageBanner", game.overViewPageBanner);
  const finderBanner = watch("finderBanner", game.finderBanner);


  const [modals, setModals] = useState({
    whiteIcon: false,
    blackIcon: false,
    horizontalDisplayImage: false,
    verticalDisplayImage: false,
    frontPageImage: false,
    frontPageVideo: false,
    scrimFinderBanner: false,
    defaultTeamLogo: false,
    defaultTeamBanner: false,
    overViewPageBanner: false,
    finderBanner: false

  });

  const toggleModal = (modalName, state) => {
    setShow(!state);
    setModals({ ...modals, [modalName]: state });
  };

  const onSubmit = async (gameData) => {
    setLoading(true);
    console.log("gameData", gameData, defaultTeamLogo);
    let formData = gameData;

    if (game) {
      // Edit
      try {
        const { data: resData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/games/${game._id}`, formData);
        if (!resData.error) {
        console.log("response data ===>>>",resData);
          toast.success("Game Updated Successfully");
          setLoading(false);
        } else {
          toast.error("Game Updating Failed");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Game Updating Failed");
      }
    }
  };

  const onUpdateColour = (clr) => {
    setValue("mainColour", clr);
  };

  const onUpdateImage = (icon, url) => {
    setValue(icon, url);
    toggleModal(icon, false);
  };

  const onUpdateVideo = (icon, url) => {
    //   console.log("icon, url-",icon, url);
    setValue(icon, url);
    toggleModal(icon, false);
  };

  return (
    <>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xl="6">
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    ref={register({ required: "Required" })}
                    type="text"
                    id="name"
                    name="name"
                    defaultValue={game.name}
                    isInvalid={errors.name}
                  />
                  <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xl="6">
                <Form.Group>
                  <Form.Label>Short name</Form.Label>
                  <Form.Control
                    ref={register({ required: "Required" })}
                    type="text"
                    id="shortName"
                    name="shortName"
                    defaultValue={game.shortName}
                    isInvalid={errors.shortName}
                    placeholder="For example: LOL"
                  />
                  <Form.Control.Feedback type="invalid">{errors.shortName && errors.shortName.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="2">
                <Form.Group>
                  <Form.Label>Ghost Tag</Form.Label>
                  <Form.Control
                    ref={register({ required: "Required" })}
                    type="text"
                    id="ghostTag"
                    name="ghostTag"
                    defaultValue={game.ghostTag}
                    isInvalid={errors.ghostTag}
                  />
                  <Form.Control.Feedback type="invalid">{errors.ghostTag && errors.ghostTag.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl="2">
                <Form.Group>
                  <Form.Label>Rules Ghost Slug</Form.Label>
                  <Form.Control
                    ref={register()}
                    type="text"
                    id="rulesGhostSlug"
                    name="rulesGhostSlug"
                    placeholder="Example: leagues-gg-rules-2"
                    defaultValue={game.rulesGhostSlug}
                    onBlur={handleSubmit(onSubmit)}
                  />
                  <Form.Control.Feedback type="invalid">{errors.rulesGhostSlug && errors.rulesGhostSlug.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl="2">
                <Form.Group>
                  <Form.Label>Game URL</Form.Label>
                  <Form.Control
                    ref={register()}
                    type="text"
                    id="gameUrl"
                    name="gameUrl"
                    placeholder="example: https://leagues.gg/game/game-name"
                    defaultValue={game.gameUrl || ""}
                    onBlur={handleSubmit(onSubmit)}
                    required={true}
                  />
                  <Form.Control.Feedback type="invalid">{errors.gameUrl && errors.gameUrl.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl="2">
                <Form.Group>integrated player profiles</Form.Group>
                <Form.Check
                  ref={register()}
                  type="checkbox"
                  id="integratedProfile"
                  name="integratedProfile"
                  defaultValue={game.integratedProfile}
                  onBlur={handleSubmit(onSubmit)}
                ></Form.Check>
              </Col>
            </Row>

            <Row>
              <Col xl="6">
                {/* Icons */}
                <div className="d-flex">
                  <Form.Group className="mr-4">
                    <Form.Label>Main Colour</Form.Label>
                    <ColorPicker
                      onLoad={register({ name: "mainColour" })}
                      mainColour={game.mainColour}
                      onUpdateColor={(clr) => onUpdateColour(clr)}
                    />
                  </Form.Group>

                  <Form.Group className="mr-4">
                    <Form.Label>White Icon</Form.Label>
                    <div
                      onLoad={register({ name: "whiteIcon" })}
                      className="edit-image-btn"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${whiteIcon})`,
                        width: 100,
                        height: 100,
                      }}
                      onClick={() => toggleModal("whiteIcon", true)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>

                  <Form.Group className="mr-4">
                    <Form.Label>Black Icon</Form.Label>
                    <div
                      onLoad={register({ name: "blackIcon" })}
                      className="edit-image-btn"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${blackIcon})`,
                        width: 100,
                        height: 100,
                      }}
                      onClick={() => toggleModal("blackIcon", true)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>

                  <Form.Group className="mr-4">
                    <Form.Label>Front Page Video</Form.Label>
                    <div
                      // src={frontPageVideo}
                      onLoad={register({ name: "frontPageVideo" })}
                      className="edit-image-btn"
                      style={{
                        width: 100,
                        height: 100,
                        position: `relative`,

                        top: 0,
                      }}
                      onClick={() => toggleModal("frontPageVideo", true)}
                    >
                      <video
                        autoplay={true}
                        src={frontPageVideo}
                        style={{
                          position: `absolute`,

                          width: `100%`,
                          height: `100%`,
                          zIndex: -1,
                        }}
                      ></video>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>
                </div>
              </Col>

              <Col xl="6">
                <div className="d-flex">
                  <Form.Group className="mr-4">
                    <Form.Label>Horizontal Display Image</Form.Label>
                    <div
                      onLoad={register({ name: "horizontalDisplayImage" })}
                      className="edit-image-btn mediaImages"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${horizontalDisplayImage})`,
                        // width: 100,
                        // height: 100,
                      }}
                      onClick={() => toggleModal("horizontalDisplayImage", true)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>

                  <Form.Group className="mr-4">
                    <Form.Label>Vertical Display Image</Form.Label>
                    <div
                      onLoad={register({ name: "verticalDisplayImage" })}
                      className="edit-image-btn mediaImages"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${verticalDisplayImage})`,
                        // width: 100,
                        // height: 100,
                      }}
                      onClick={() => toggleModal("verticalDisplayImage", true)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>

                  <Form.Group className="mr-4">
                    <Form.Label>Front Page Image</Form.Label>
                    <div
                      onLoad={register({ name: "frontPageImage" })}
                      className="edit-image-btn mediaImages"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${frontPageImage})`,
                        // width: 100,
                        // height: 100,
                      }}
                      onClick={() => toggleModal("frontPageImage", true)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>
                </div>
                <div className="d-flex">
                  <Form.Group className="mr-4">
                    <Form.Label>Scrim Finder Banner</Form.Label>
                    <div
                      onLoad={register({ name: "scrimFinderBanner" })}
                      className="edit-image-btn mediaImages"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${scrimFinderBanner})`,
                        // width: 100,
                        // height: 100,
                      }}
                      onClick={() => toggleModal("scrimFinderBanner", true)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>

                  <Form.Group className="mr-4">
                    <Form.Label>Default Team Logo</Form.Label>
                    <div
                      onLoad={register({ name: "defaultTeamLogo" })}
                      className="edit-image-btn mediaImages"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${defaultTeamLogo})`,
                      }}
                      onClick={() => toggleModal("defaultTeamLogo", true)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>

                  <Form.Group className="mr-4">
                    <Form.Label>Default Team Banner</Form.Label>
                    <div
                      onLoad={register({ name: "defaultTeamBanner" })}
                      className="edit-image-btn mediaImages"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${defaultTeamBanner})`,
                        // width: 100,
                        // height: 100,
                      }}
                      onClick={() => toggleModal("defaultTeamBanner", true)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>

                </div>
                <div className="d-flex">
                  <Form.Group className="mr-4">
                    <Form.Label>Overview Page Banner</Form.Label>
                    <div
                      onLoad={register({ name: "overViewPageBanner" })}
                      className="edit-image-btn mediaImages"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${overViewPageBanner})`,
                        // width: 100,
                        // height: 100,
                      }}
                      onClick={() => toggleModal("overViewPageBanner", true)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>
                  <Form.Group className="mr-4">
                    <Form.Label>Finder Banner</Form.Label>
                    <div
                      onLoad={register({ name: "finderBanner" })}
                      className="edit-image-btn mediaImages"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${finderBanner})`,
                        // width: 100,
                        // height: 100,
                      }}
                      onClick={() => toggleModal("finderBanner", true)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>
                </div>
              </Col>
            </Row>
           
            <Row>
              <Col xl="3">
                <Button type="submit" variant="success" className="float-left" disabled={loading}>
                  {loading ? "Processing..." : game ? "Save" : "Create"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <EditImageModal
          show={modals.whiteIcon}
          onHide={() => toggleModal("whiteIcon", false)}
          name="White Game Icon"
          width={200}
          height={200}
          afterImageUpload={(file) => onUpdateImage("whiteIcon", file)}
        />
        <EditImageModal
          show={modals.blackIcon}
          onHide={() => toggleModal("blackIcon", false)}
          name="Black Game Icon"
          width={200}
          height={200}
          afterImageUpload={(file) => onUpdateImage("blackIcon", file)}
        />
        <EditImageModal
          show={modals.horizontalDisplayImage}
          onHide={() => toggleModal("horizontalDisplayImage", false)}
          name="Horizontal Display Image"
          width={544}
          height={306}
          afterImageUpload={(file) => onUpdateImage("horizontalDisplayImage", file)}
        />

        <EditImageModal
          show={modals.verticalDisplayImage}
          onHide={() => toggleModal("verticalDisplayImage", false)}
          name="Vertical Display Image"
          width={306}
          height={544}
          afterImageUpload={(file) => onUpdateImage("verticalDisplayImage", file)}
        />

        <EditImageModal
          show={modals.frontPageImage}
          onHide={() => toggleModal("frontPageImage", false)}
          name="Front Page Image"
          width={200}
          height={200}
          afterImageUpload={(file) => onUpdateImage("frontPageImage", file)}
        />
        <EditImageModal
          show={modals.scrimFinderBanner}
          onHide={() => toggleModal("scrimFinderBanner", false)}
          name="Scrim Finder Banner"
          description="The image ratio is 6:1 with a default size of 1920x320 and a minimum size of 1200x200."
          width={1200}
          height={200}
          afterImageUpload={(file) => onUpdateImage("scrimFinderBanner", file)}
        />
        <EditImageModal
          show={modals.defaultTeamLogo}
          onHide={() => toggleModal("defaultTeamLogo", false)}
          name="Default Team Logo"
          description="The image ratio is 1:1 with a maximum size of 2000x2000 and a minimum size of 250x250."
          width={250}
          height={250}
          afterImageUpload={(file) => onUpdateImage("defaultTeamLogo", file)}
          afterImageUploadFullSize={(file) => onUpdateImage("defaultTeamLogo", file)}

        />
        <EditImageModal
          show={modals.defaultTeamBanner}
          onHide={() => toggleModal("defaultTeamBanner", false)}
          name="Default Team Banner"
          description="The image ratio is 6:1 with a default size of 1920x320 and a minimum size of 1200x200."
          width={1200}
          height={200}
          afterImageUpload={(file) => onUpdateImage("defaultTeamBanner", file)}
        />
         <EditImageModal
          show={modals.overViewPageBanner}
          onHide={() => toggleModal("overViewPageBanner", false)}
          name="Overview Page Banner"
          description="The image ratio is 6:1 with a default size of 1920x320 and a minimum size of 1200x200."
          width={1200}
          height={200}
          afterImageUpload={(file) => onUpdateImage("overViewPageBanner", file)}
        />
        <EditImageModal
          show={modals.finderBanner}
          onHide={() => toggleModal("finderBanner", false)}
          name="Overview Page Banner"
          description="The image ratio is 6:1 with a default size of 1920x320 and a minimum size of 1200x200."
          width={1200}
          height={200}
          afterImageUpload={(file) => onUpdateImage("finderBanner", file)}
        />
        <EditVideoModal
          show={modals.frontPageVideo}
          video={frontPageVideo || null}
          onHide={() => toggleModal("frontPageVideo", false)}
          name="Front Page Video"
          width={200}
          height={200}
          afterImageUpload={(file) => onUpdateVideo("frontPageVideo", file)}
        />
      </Row>
    </>
  );
};

export default General;