import React, { useState, useRef, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import Axios from "axios";
import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faRedo } from "@fortawesome/free-solid-svg-icons";

const EditImageModal = ({
  show,
  onHide,
  name,
  width,
  height,
  crop = true,
  rotate = true,
  borderRadius,
  afterImageUpload,
  afterImageUploadFullSize,
  description = "",
}) => {
  const editor = useRef();
  const [image, setImage] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [uploading, setUploading] = useState(false);

  const clearImage = () => setImage(false);
  const handleScale = (e) => setScale(parseFloat(e.target.value));
  const rotateLeft = () => setRotation(rotation - 90);
  const rotateRight = () => setRotation(rotation + 90);

  useEffect(() => {
    clearImage();
    setUploading(false);
  }, [show]);

  useEffect(() => {
    setRotation(0);
    setScale(1);
  }, [image]);

  const onClickSave = () => {
    const canvas = !crop ? editor.current.getImage() : editor.current.getImageScaledToCanvas();

    canvas.toBlob((blob) => {
      const file = new File([blob], `${name}-${width}-${height}.png`, {
        type: "image/png",
      });

      const formData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      formData.append("image", file);

      setUploading(true);

      Axios.post(`${process.env.REACT_APP_CORE_API}/api/upload`, formData, config)
        .then(({ data }) => {
          console.log({data})
          afterImageUpload(data.file);
          setUploading(false);
        })
        .catch((err) => {
          const msg = err.response.data ? err.response.data.msg : "There was a problem uploading your file";
          toast.error(msg);
          setUploading(false);
        });
    });

    if (afterImageUploadFullSize) {
      const file = new File([image], `${name}.png`, {
        type: "image/png",
      });

      const formData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      formData.append("image", file);

      Axios.post(`${process.env.REACT_APP_CORE_API}/api/upload`, formData, config).then(({ data }) => {
        afterImageUploadFullSize(data.file);
      });
    }
  };
  const handleDrop = (dropped) => {
    // Get image from drop
    const image = dropped[0];
    const imageObj = new Image();
    imageObj.src = window.URL.createObjectURL(image);

    imageObj.onload = () => {
      const imageSize = image && image.size;
      const imageWidth = imageObj.width;
      const imageHeight = imageObj.height;

      // Is image less than 8MB?
      if (imageSize > 8000000) return toast.error("File must be smaller than 8MB");
      // Is image the minimum dimensions?
      if (imageWidth < width || imageHeight < height) return toast.error(`File must be at least ${width}px x ${height}px in size`);
      if(width === 1200){
        if((imageWidth/6) !== imageHeight){
          return toast.error(`File must be at 6:1 in dimensions`)
        }
      }
      if(width === 640){
        let ratio1 = imageWidth/16 
        let ratio2 = imageHeight/9 
        if(ratio1 !== ratio2){
          return toast.error(`File must be at 16:9 in dimensions`)
        }
      }

      setImage(dropped[0]);
    };
  };
  return (
    <Modal show={show} onHide={onHide} size={width > 499 && width < 699 ? "lg" : width > 699 ? "xl" : false}>
      <Modal.Header closeButton>
        <Modal.Title>Upload {name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {description && <div>{description}</div>}
        {image ? (
          <div className="editing-image">
            <div className="avatar-editor-backdrop">
              <AvatarEditor
                ref={editor}
                width={width}
                height={height}
                image={image}
                scale={scale}
                rotate={rotation}
                borderRadius={borderRadius}
                border={0}
              />
            </div>
            <div className="avatar-editor-controls">
              <Form.Group>
                <Form.Label>Zoom</Form.Label>
                <Form.Control name="scale" type="range" value={scale} onChange={handleScale} min="1" max="2" step="0.01" defaultValue="1" />
              </Form.Group>

              {rotate && (
                <Form.Group>
                  <Form.Label>Rotation</Form.Label>
                  <div>
                    <Button variant="link" className="text-secondary" onClick={rotateLeft}>
                      <FontAwesomeIcon icon={faUndo} /> Left
                    </Button>
                    <Button variant="link" className="text-secondary" onClick={rotateRight}>
                      <FontAwesomeIcon icon={faRedo} /> Right
                    </Button>
                  </div>
                </Form.Group>
              )}

              <div className="d-flex justify-content-between">
                <Button variant="secondary" onClick={clearImage}>
                  Restart
                </Button>
                <Button variant="success" onClick={onClickSave} disabled={uploading}>
                  {uploading ? "Uploading..." : "Upload"}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Dropzone
            multiple={false}
            accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
            onDrop={handleDrop}
            validator={(file) => {
              // console.log("File Width", file, file.width)
              const img = new Image();
              img.src = file;
              //console.log(img.naturalHeight, img.naturalWidth, img)
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="image-dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag and drop or click to upload image</p>
              </div>
            )}
          </Dropzone>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditImageModal;