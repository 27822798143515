import React, { useState, useEffect, createRef } from "react";
import { Modal, Form, Button, Tooltip, OverlayTrigger, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDownload, faKey, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { toast } from "react-toastify";

const MatchScoreModal = ({ show, onHide, match, updateMatches, setLeague, isScrim, codesLoading, setGenerateCodeloaderSpinner }) => {
  const elRefs = React.useRef([]);
  const [t1Score, setT1Score] = useState(0);
  const [t2Score, setT2Score] = useState(0);
  const [t1FF, setT1FF] = useState(false);
  const [t2FF, setT2FF] = useState(false);
  const [scoreReason, setScoreReason] = useState("");
  const [tournamentCodes, setTournamentCodes] = useState([]);
  const [LeaderBoard, setLeaderBoard] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [resettingMatch, setResettingMatch] = useState(false);
  const [genCodes, setGenCodes] = useState(codesLoading && codesLoading == match._id);

  useEffect(() => {
    setGenCodes(!!codesLoading && codesLoading == match._id);
  }, [codesLoading, match]);

  useEffect(() => {
    setT1Score(match.t1Score);
    setT2Score(match.t2Score);
    setT1FF(match.t1FF);
    setT2FF(match.t2FF);
    setScoreReason(match.scoreReason || "");
    setTournamentCodes(match.lolData && match.lolData.tournamentCodes ? match.lolData.tournamentCodes : []);
  }, [match]);

  const onSubmitScore = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    
    try {
      let params = isScrim ? "?type=Scrim" : "";
      const { data } = await Axios.put(`${process.env.REACT_APP_LOL_API}/api/matches/${match._id}${params}`, {
        t1Score,
        t2Score,
        t1FF,
        t2FF,
        scoreReason,
      });
      if (isScrim) {
        updateMatches(match._id, data?.updatedMatch);
      } else {
        updateMatches((matches) => matches.map((m) => (m._id === match._id ? data?.updatedMatch : m)));
        setLeague((league) => ({ ...league, teams: data?.updatedLeague?.teams }));
      }

      toast.success("Saved score");
      onHide();
    } catch (e) {
      toast.error("There was a problem saving score 😞");
    }

    setSubmitting(false);
  };

  const onManuallyFetch = async () => {
    setFetchingData(true);

    try {
      const { data } = await Axios.get(`${process.env.REACT_APP_LOL_API}/api/matches/${match._id}/manuallyGetData?type=3`);
      updateMatches((matches) => matches.map((m) => (m._id === match._id ? data?.updatedMatch : m)));
      let _league = { ...data?.updatedLeague, matches: [data?.updatedMatch] };
      if (!isScrim) {
        setLeague((league) => ({ ...league, teams: data?.updatedLeague?.teams ?? league.teams }));
      } else {
        setLeaderBoard(_league);
        setLeague((league) => ({ ...league, teams: data?.updatedLeague?.teams ?? league.teams }));
      }
      setT1Score(data.updatedMatch.t1Score);
      setT2Score(data.updatedMatch.t2Score);

      toast.success("Manually fetched match data from RIOT");
    } catch (e) {
      toast.error("RIOT could not get data/find any data from the tournament codes");
    }

    setFetchingData(false);
  };

  const onGenTournamentCodes = async () => {
    setGenCodes(true);
    setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(match._id);

    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_LOL_API}/api/matches/${match._id}/generateTournamentCodes${isScrim ? "?type=Scrim" : ""}`
      );

      updateMatches((matches) => matches.map((m) => (m._id === match._id ? data?.updatedMatch : m)));
      setTournamentCodes(data.updatedMatch.lolData.tournamentCodes);

      toast.success("Manually generated tournament codes 😍");
    } catch (e) {
      toast.error(e?.response?.data?.msg || "RIOT could not generate tournament codes");
    }

    setGenCodes(false);
    setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(false);
  };

  const onResetMatch = async () => {
    setResettingMatch(true);

    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_LOL_API}/api/matches/${match._id}/resetData`);
      console.log("reset data -- ", data);
      let _league = { ...data?.updatedLeague, matches: [data?.updatedMatch] };
      updateMatches((matches) => matches.map((m) => (m._id === match._id ? data?.updatedMatch : m)));
      if (!isScrim) {
        setLeague((league) => ({ ...league, teams: _league?.teams ?? league.teams }));
      } else {
        setLeaderBoard(_league);
        setLeague((league) => ({ ...league, teams: data?.updatedLeague?.teams ?? league.teams }));
      }
      setT1Score(0);
      setT2Score(0);
      setT1FF(false);
      setT2FF(false);
      setScoreReason("");

      toast.success("Successfully reset match!");
    } catch (e) {
      toast.error("There was a problem resetting this match 😞");
    }

    setResettingMatch(false);
  };

  if (elRefs.current.length !== tournamentCodes.length) {
    // add or remove refs
    elRefs.current = Array(tournamentCodes.length)
      .fill()
      .map((_, i) => elRefs.current[i] || createRef());
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manually Set Score</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Score</p>

        <Form onSubmit={onSubmitScore}>
          <div className="d-flex">
            <Form.Group style={{ width: "100%" }}>
              <div className="input-group">
                <span class="input-group-addon prefix" onClick={() => setT1Score(t1Score + 1)}>
                  +
                </span>
                <Form.Control type="number" value={t1Score} onChange={(e) => setT1Score(parseFloat(e.target.value))} required />
                <span class="input-group-addon suffix" onClick={() => setT1Score(t1Score - 1)}>
                  -
                </span>
              </div>

              <small className="text-secondary d-flex justify-content-between">
                <span>{match.t1 ? match.t1.name : "BYE"}</span>
                <Form.Check label="FF" checked={t1FF} onChange={(e) => setT1FF(e.target.checked)} />
              </small>
            </Form.Group>

            <h4 className="ml-2 mr-2">-</h4>

            <Form.Group style={{ width: "100%" }}>
              <div className="input-group">
                <span class="input-group-addon prefix" onClick={() => setT2Score(t2Score + 1)}>
                  +
                </span>
                <Form.Control type="number" value={t2Score} onChange={(e) => setT2Score(parseFloat(e.target.value))} required />
                <span class="input-group-addon suffix" onClick={() => setT2Score(t2Score - 1)}>
                  -
                </span>
              </div>

              <small className="text-secondary d-flex justify-content-between">
                <span>{match.t2 ? match.t2.name : "BYE"}</span>
                <Form.Check label="FF" checked={t2FF} onChange={(e) => setT2FF(e.target.checked)} />
              </small>
            </Form.Group>
          </div>

          <Form.Group>
            <Form.Label>Reason</Form.Label>
            <Form.Control type="text" value={scoreReason} onChange={(e) => setScoreReason(e.target.value)} />
          </Form.Group>

          <div className="d-flex justify-content-end">
            <Button type="submit" size="sm" variant="success" disabled={submitting}>
              {submitting ? "Saving..." : "Save"}
            </Button>
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer className="d-block p-3">
        <>
          <p>Quick Actions</p>

          {t1Score || t2Score ? (
            <Button variant="link" className="d-block p-0 mb-1 text-danger" onClick={onResetMatch} disabled={resettingMatch}>
              <FontAwesomeIcon icon={faRedoAlt} /> {resettingMatch ? "Resetting" : "Reset"} Match
            </Button>
          ) : (
            match.lolData &&
            !!tournamentCodes.length && (
              <Button variant="link" className="d-block p-0 mb-1" onClick={onManuallyFetch} disabled={fetchingData}>
                <FontAwesomeIcon icon={faDownload} /> {fetchingData ? "Fetching" : "Manually Fetch"} Data
              </Button>
            )
          )}
        </>

        <Button variant="link" className="d-block p-0 mb-1" onClick={onGenTournamentCodes} disabled={genCodes}>
          <FontAwesomeIcon icon={faKey} /> {genCodes ? "Generating" : "Generate"} Tournament Codes
          {genCodes && (
            <span className="ml-2">
              <Spinner animation="border" className="m-auto" size="sm" role="status"></Spinner>
            </span>
          )}
        </Button>
      </Modal.Footer>

      {match.lolData && !!tournamentCodes.length && (
        <Modal.Footer className="d-block p-3">
          <p>Tournament Codes</p>

          {tournamentCodes.map((code, i) => (
            <Form.Group key={i}>
              <Form.Label className="d-block text-secondary">
                <small>Map {i + 1}:</small>
              </Form.Label>
              <div className="d-flex">
                <Form.Control
                  ref={elRefs.current[i]}
                  readOnly
                  style={{ fontFamily: "monospace", fontSize: "12px" }}
                  size="sm"
                  type="text"
                  value={code}
                />
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Copy</Tooltip>}>
                  <Button
                    variant="link"
                    size="sm"
                    className="text-primary"
                    onClick={() => {
                      elRefs.current[i].current.focus();
                      elRefs.current[i].current.select();
                      document.execCommand("copy");
                      toast.success("Copied to clipboard ✅");
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </OverlayTrigger>
              </div>
            </Form.Group>
          ))}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default MatchScoreModal;
